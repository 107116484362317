var generic = generic || {};
var site = site || {};

(function ($) {
  site.emailSignupFooterTopV1 = {
    templateContainer: $('.footer-top-mobile-v1'),

    collateMessages: function (messages) {
      if (Array.isArray(messages)) {
        var len = messages.length;
        var tmp = [];

        for (var i = 0; i < len; i++) {
          tmp.push(messages[i].text);
        }

        return tmp.join('<br />');
      }

      return;
    },

    hideAddressBar: function () {
      window.scrollTo(0, 1);
    },

    initEmailSignup: function () {
      var self = this;
      var emailContainerNode = this.templateContainer.find('.newsletter-signup');

      if (!emailContainerNode) {
        return null;
      }

      var emailFormNode = emailContainerNode.find('form'),
        emailProgressNode = emailContainerNode.find('div.progress'),
        emailSuccessNode = emailContainerNode.find('div.success'),
        emailErrorNode = emailContainerNode.find('div.error'),
        emailInputNode = emailContainerNode.find("input[name='PC_EMAIL_ADDRESS']"),
        acceptInputNode = emailContainerNode.find("input[name='PC_EMAIL_PROMOTIONS']");

      emailFormNode.once('newsletter-signup-form').on('submit', function (submitEvt) {
        submitEvt.preventDefault();

        emailSuccessNode.addClass('hidden');
        emailErrorNode.addClass('hidden');
        emailInputNode.removeClass('error');

        // we want to get all the fields in code because 'serialize' encodes potentially bad emails and decode doesn't decode '+' as spaces
        var fields = [
          'PC_EMAIL_ADDRESS',
          'PC_EMAIL_PROMOTIONS',
          'PC_EMAIL_PROMOTIONS_PRESENT',
          'ACCEPTED_COMMERCIAL',
          'ACCEPTED_COMMERCIAL_PRESENT',
          'LAST_SOURCE',
          'LANGUAGE_ID',
          'COUNTRY_ID'
        ];
        var paramObj = {};

        // loop through all the fields and get the values
        $.each(fields, function (index, value) {
          var formField = $('#newsletter-signup input[name=' + value + ']');

          // for the unchecked checkboxes we want to send empty strings to backend for processing
          if (formField.is(':checkbox') && !formField.prop('checked')) {
            paramObj[value] = '';
          } else {
            paramObj[value] = formField.val();
          }
        });

        paramObj['_SUBMIT'] = 'email_signup';

        // Send the data via a json rpc call
        generic.jsonrpc.fetch({
          method: 'rpc.form',
          params: [paramObj],
          onSuccess: function (jsonRpcResponse) {
            emailProgressNode.addClass('hidden');
            $('#newsletter-signup').hide();

            var response = jsonRpcResponse.getData();
            // was the user previously opted into email promotions before this submission?
            // this is a 0/1 value
            var previously_opted_in_email_promotion =
              response.userinfo.previously_opted_in_email_promotion;
            // are we still running special offer codes which are emailed out?
            // this is a 0/1 value
            var special_offer_code_status = response.userinfo.special_offer_code_status;

            // user was not previously opted and we are running special offer emails
            // so show overlay with the offer code
            if (!previously_opted_in_email_promotion && special_offer_code_status) {
              emailSuccessNode.html('');

              // show popup window with special offer code
              $('.popup-offer-v1').show();
              $('.outer-wrap.signup').hide();

              // Adjust the popup based on the #footer position
              var footer = $('#footer'),
                offset = footer.offset();

              $('.outer-wrap.thankyou').show().css({ top: 'auto' });
            }

            // show normal success message
            else {
              emailInputNode.removeClass('error');
              emailSuccessNode.removeClass('hidden');
            }
          },
          onFailure: function (jsonRpcResponse) {
            var messages = self.collateMessages(jsonRpcResponse.getMessages());

            emailProgressNode.addClass('hidden');
            emailFormNode.addClass('error');
            emailErrorNode.html(messages).removeClass('hidden');
          }
        });
      });
    },

    initSMSSignup: function () {
      var self = this;
      var emailContainerNode = this.templateContainer.find('.mobile-signup');

      if (!emailContainerNode) {
        return null;
      }

      var emailFormNode = emailContainerNode.find('form'),
        emailProgressNode = emailContainerNode.find('div.progress'),
        emailSuccessNode = emailContainerNode.find('div.success'),
        emailErrorNode = emailContainerNode.find('div.error'),
        phoneInputNode = emailContainerNode.find("input[name='MOBILE_NUMBER']"),
        acceptInputNode = emailContainerNode.find("input[name='SMS_PROMOTIONS']");

      emailFormNode.once('mobile-signup-form').on('submit', function (submitEvt) {
        submitEvt.preventDefault();

        emailSuccessNode.addClass('hidden');
        emailErrorNode.addClass('hidden');
        emailProgressNode.removeClass('hidden');

        // we want to get all the fields in code because 'serialize' encodes potentially bad emails and decode doesn't decode '+' as spaces
        var fields = [
          'MOBILE_NUMBER',
          'SMS_PROMOTIONS',
          'CARRIER_CODE',
          'LAST_SOURCE',
          'ORIGINAL_SOURCE',
          'COUNTRY_ID',
          'LANGUAGE_ID'
        ];
        var paramObj = {};

        // loop through all the fields and get the values
        $.each(fields, function (index, value) {
          var formField = $('#mobile-signup input[name=' + value + ']');

          if (formField.is(':checkbox') && !formField.prop('checked')) {
            paramObj[value] = 0;
          } else {
            paramObj[value] = formField.val();
          }
        });

        paramObj['_SUBMIT'] = 'sms_mobile_footer';

        // Send the data via a json rpc call
        generic.jsonrpc.fetch({
          method: 'rpc.form',
          params: [paramObj],
          onSuccess: function (jsonRpcResponse) {
            emailProgressNode.addClass('hidden');
            emailSuccessNode.removeClass('hidden');
            $('#mobile-signup').hide();
          },
          onFailure: function (jsonRpcResponse) {
            var messages = self.collateMessages(jsonRpcResponse.getMessages());

            emailProgressNode.addClass('hidden');
            emailErrorNode.html(messages).removeClass('hidden');
          }
        });
      });
    },

    gdprRemInfoMessageBox: function () {
      if ($('.info-msg').get(0)) {
        $('.info-msg').remove();
      }
    },

    gdprSetInfoBoxPosition: function (infoLink, infoMessageBox, infoMsgArrow, isMobile) {
      var infoLinkTop = infoLink.offset().top + infoLink.height() + 10;
      var infoLinkLeft = infoLink.offset().left;
      var infoMsgMaxWidth = parseInt(infoMessageBox.css('max-width').replace('px', ''));

      if (isMobile) {
        infoMsgArrow.css({
          left: infoLinkLeft - 10 + 'px'
        });
        infoLinkLeft = 10;
      } else if (
        infoMessageBox.outerWidth(true) === infoMsgMaxWidth &&
        infoLinkLeft + (infoMsgMaxWidth + 25) > screen.width
      ) {
        infoLinkLeft = infoLinkLeft - 480;
        infoMsgArrow.addClass('top-right-arrow');
      }
      infoMessageBox.css({
        top: infoLinkTop + 'px',
        left: infoLinkLeft + 'px'
      });
    },

    gdprToolTip: function (context) {
      if (Drupal.settings.globals_variables.gdpr_compliant) {
        var isMobile =
          typeof Unison !== 'undefined'
            ? Unison.fetch.now().name === 'usn-zero'
            : $.cookie('client.isMobile');

        $('body', context).on('click', function(e) {
          if (e.target.className !== 'info-msg' && e.target.className !== 'info-msg__txt') {
            $('.info-msg').addClass('hidden');
          }
          if ($('html').hasClass('colorbox_scroll')) {
            $('html').removeClass('colorbox_scroll');
          }
        });

        $(context).on('mousedown', '.info-msg__txt a', function (mouseEvent) {
          mouseEvent.preventDefault();
        });

        $(context).on('click', '.info-link', function (infoEvent) {
          infoEvent.preventDefault();
          var infoLink = $(infoEvent.currentTarget);

          if ($('.popup-offer-v1').is(':visible')) {
            $('html').addClass('colorbox_scroll');
          }
          site.emailSignupFooterTopV1.gdprRemInfoMessageBox();

          $(
            "<div class='info-msg'><div class='info-msg__arrow'></div><div class='info-msg__txt'></div></div>"
          ).appendTo('body');
          var infoBoxEle = $('#' + infoLink.attr('message-in'));
          var infoMessageBox = $('.info-msg');
          var infoMsgArrow = $('.info-msg__arrow');

          $('.info-msg__txt').html("<div class='info-msg__close'>X</div>" + infoBoxEle.html());

          $(context).on('click', '#cboxClose, .info-msg__close', function (closeEvent) {
            closeEvent.stopPropagation();
            site.emailSignupFooterTopV1.gdprRemInfoMessageBox();
            if ($('.popup-offer-v1').is(':visible')) {
              $('html').removeClass('colorbox_scroll');
            }
          });

          site.emailSignupFooterTopV1.gdprSetInfoBoxPosition(
            infoLink,
            infoMessageBox,
            infoMsgArrow,
            isMobile
          );
          $(window).on(
            'resize-debounced',
            site.emailSignupFooterTopV1.gdprSetInfoBoxPosition(
              infoLink,
              infoMessageBox,
              infoMsgArrow,
              isMobile
            )
          );
        });
      }
    }
  };

  Drupal.behaviors.emailSignupFooterTopV1 = {
    attach: function (context, settings) {
      site.emailSignupFooterTopV1.initEmailSignup();
      site.emailSignupFooterTopV1.initSMSSignup();
      site.emailSignupFooterTopV1.hideAddressBar();
      site.emailSignupFooterTopV1.gdprToolTip(context);
    }
  };
})(jQuery);
